.fourth-section {
    padding: 50px 20px;
    color: --font-color;
    text-align: center;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .action-button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .content-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .text-content {
    flex: 1;
    min-width: 300px;
    margin-right: 20px;
    text-align: left;
  }
  
  .small-title {
    color: #00ffb3; /* Adjust the highlight color */
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .fourthSection-main-title {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .features-list {
    list-style: none;
    padding-left: 0;
  }
  
  .features-list li {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .code-container {
    flex: 1;
    min-width: 300px;
    background-color: #1c1c1c; /* Adjust the box background color */
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }
  
  .copy-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  