.third-section {
    text-align: center;
    padding: 50px 20px;

  }
  
  .third-section .section-title {
    font-size: 36px;
    margin-bottom: 20px;
    color: --font-color;
  }
  
  .section-description {
    font-size: 18px;
    margin-bottom: 10px;

  }
  
  .section-description .highlight {
    font-weight: bold;
    color: #00ffb3; /* Adjust the highlight color */
  }
  
  .diagram-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    color: #ffff;
  }
  
  .diagram-container > div {
    background-color: #1c1c1c; /* Adjust the box background color */
    padding: 20px;
    border-radius: 10px;
    flex: 1;
    min-width: 150px;
    text-align: left;
  }
  
  .data-source p,
  .data-ingestion p,
  .semantic-layer p,
  .graphql-api p,
  .applications p {
    margin: 10px 0;
  }
  
  .data-ingestion {
    background-color: #5b6efc; /* Adjust the color of Data Ingestion */
  }
  
  .graphql-api {
    background-color: #8a2be2; /* Adjust the color of GraphQL API */
  }
  