:root {
  --font-color: #111111;
  --background-color: #fff;
}

.App {
  text-align: center;
}

body{
  background-color: --background-color; /* Background color */

}
